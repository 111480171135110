// Imports => Constants
import { ENDPOINTS } from '@constants';

// Imports => Utilities
import { AcMemoize } from '@utils';

export class FaqAPI {
	constructor(Instance) {
		this.Store = Instance.Store;
		this.Client = Instance.Client;
	}

	index = AcMemoize(
		() => {
			return this.Client.get(ENDPOINTS.FAQ.INDEX).then((response) => response.data);
		},
		12 * 60 * 60 * 1000
	);
}

export default FaqAPI;
