// Imports => Constants
import { TITLES } from './titles.constants';
import { ICONS } from './icons.constants';

// Imports => Utilities
import { AcUUID } from '@utils/ac-uuid';
import { AcLockObject } from '@utils/ac-lock-object';

// Imports => Views
import {
	AcRegister,
	AcDocuments,
	AcFaq,
	AcForgotPassword,
	AcImpersonate,
	AcLogin,
	AcHome,
	AcNewsOverview,
	AcNewsDetail,
	AcNotFound,
	AcProfile,
	AcProjectOverview,
	AcProjectDetail,
	AcResetPassword,
} from '@views';

export const PATHS = AcLockObject({
	AUTH: {
		LOGIN: '/login',
		FORGOT_PASSWORD: '/forgot-password',
		RESET_PASSWORD: '/password/reset/:token',
		REGISTER: '/register/:token',
		IMPERSONATE: '/impersonate',
	},
	HOME: '/',
	FAQ: '/faq',
	PROJECT_OVERVIEW: '/portfolio',
	PROJECT_DETAIL: '/portfolio/project/:id',
	DOCUMENTS: '/documents',
	NEWS_OVERVIEW: '/news',
	NEWS_DETAIL: '/news/:id',
	PROFILE: '/profile',
	NOT_FOUND: '/404-not-found',
});

export const ROUTES = {
	REGISTER: {
		id: AcUUID(),
		name: 'Register',
		label: TITLES.REGISTER,
		path: PATHS.AUTH.REGISTER,
		component: AcRegister,
		$ref: null,
		roles: null,
		forbidden: false,
	},
	IMPERSONATE: {
		id: AcUUID(),
		name: 'Impersonate',
		label: TITLES.IMPERSONATE,
		path: PATHS.AUTH.IMPERSONATE,
		component: AcImpersonate,
		$ref: null,
		roles: null,
		forbidden: false,
	},
	DOCUMENTS: {
		id: AcUUID(),
		name: 'Documents',
		label: TITLES.DOCUMENTS,
		path: PATHS.DOCUMENTS,
		component: AcDocuments,
		title: 'Belangrijke documenten',
		icon: ICONS.TEXT_BOX_OUTLINE,
		$ref: null,
		roles: null,
		forbidden: true,
	},
	FAQ: {
		id: AcUUID(),
		name: 'FAQ',
		label: TITLES.FAQ,
		path: PATHS.FAQ,
		component: AcFaq,
		title: 'Veelgestelde vragen & antwoorden',
		locale: 'nl',
		icon: ICONS.TEXT_BOX_OUTLINE,
		$ref: null,
		roles: null,
		forbidden: true,
	},
	FORGOT_PASSWORD: {
		id: AcUUID(),
		name: 'Forgot password',
		label: TITLES.FORGOT_PASSWORD,
		path: PATHS.AUTH.FORGOT_PASSWORD,
		component: AcForgotPassword,
		$ref: null,
		roles: null,
		forbidden: false,
	},
	HOME: {
		id: AcUUID(),
		name: 'Home',
		label: TITLES.HOME,
		path: PATHS.HOME,
		cleanpath: PATHS.HOME,
		component: AcHome,
		title: 'Jouw dashboard',
		icon: ICONS.HOME,
		$ref: null,
		roles: null,
		forbidden: true,
	},
	LOGIN: {
		id: AcUUID(),
		name: 'Login',
		label: TITLES.LOGIN,
		path: PATHS.AUTH.LOGIN,
		component: AcLogin,
		$ref: null,
		roles: null,
		forbidden: false,
	},
	PROJECT_OVERVIEW: {
		id: AcUUID(),
		name: 'Project Overview',
		label: TITLES.PROJECT_OVERVIEW,
		path: PATHS.PROJECT_OVERVIEW,
		component: AcProjectOverview,
		icon: ICONS.BALLOT_OUTLINE,
		$ref: null,
		roles: null,
		forbidden: true,
	},
	PROJECT_DETAIL: {
		id: AcUUID(),
		name: 'Project Detail',
		label: TITLES.PROJECT,
		path: PATHS.PROJECT_DETAIL,
		component: AcProjectDetail,
		parent: PATHS.PROJECT_OVERVIEW,
		$ref: null,
		roles: null,
		forbidden: true,
	},
	NEWS_OVERVIEW: {
		id: AcUUID(),
		name: 'News',
		label: TITLES.NEWS,
		path: PATHS.NEWS_OVERVIEW,
		component: AcNewsOverview,
		icon: ICONS.NEWSPAPER_O,
		title: 'Al het nieuws op 1 plek',
		locale: 'nl',
		$ref: null,
		roles: null,
		forbidden: true,
	},
	NEWS_DETAIL: {
		id: AcUUID(),
		name: 'News Detail',
		label: TITLES.NEWS,
		path: PATHS.NEWS_DETAIL,
		component: AcNewsDetail,
		parent: PATHS.NEWS_OVERVIEW,
		locale: 'nl',
		$ref: null,
		roles: null,
		forbidden: true,
	},
	PROFILE: {
		id: AcUUID(),
		name: 'Profile',
		label: TITLES.PROFILE,
		path: PATHS.PROFILE,
		component: AcProfile,
		icon: ICONS.ACCOUNT_CIRCLE_OUTLINE,
		title: 'Jouw profiel en instellingen',
		$ref: null,
		roles: null,
		forbidden: true,
	},
	RESET_PASSWORD: {
		id: AcUUID(),
		name: 'Reset password',
		label: TITLES.RESET_PASSWORD,
		path: PATHS.AUTH.RESET_PASSWORD,
		component: AcResetPassword,
		$ref: null,
		roles: null,
		forbidden: false,
	},
	NOT_FOUND: {
		id: AcUUID(),
		name: '404 - Not Found',
		label: TITLES.NOT_FOUND,
		path: PATHS.NOT_FOUND,
		component: AcNotFound,
		$ref: null,
		roles: null,
		render: false,
		forbidden: false,
	},
};

export const NAVIGATION_ITEMS = [
	ROUTES.HOME,
	ROUTES.PROJECT_OVERVIEW,
	ROUTES.NEWS_OVERVIEW,
	ROUTES.FAQ,
];

export const SUB_NAVIGATION_ITEMS = [ROUTES.PROFILE, ROUTES.DOCUMENTS];

export const AUTHENTICATION_ROUTES = [
	PATHS.AUTH.IMPERSONATE,
	PATHS.AUTH.REGISTER,
	PATHS.AUTH.FORGOT_PASSWORD,
	PATHS.AUTH.LOGIN,
	PATHS.AUTH.RESET_PASSWORD,
];

export const NOT_FOUND_ROUTE = ROUTES.NOT_FOUND;
export const DEFAULT_ROUTE = ROUTES.HOME;
export const REDIRECT_ROUTE = ROUTES.LOGIN;
