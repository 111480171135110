import config from '@config';

import { AcLockObject } from '@utils/ac-lock-object';

export const TITLES = AcLockObject({
	ACTIVATE: 'Activeren',
	BASE: 'Mijn REALIANCE',
	BASE_EN: 'My REALIANCE',
	CONVERSATIONS: 'Meldingen',
	DOCUMENTS: 'Documenten',
	FREQUENTLY_ASKED_QUESTIONS: 'Veelgestelde vragen',
	FAQ: 'FAQ',
	FORGOT_PASSWORD: 'Wachtwoord vergeten',
	HOME: 'Dashboard',
	LOGIN: 'Inloggen',
	MESSAGES: 'Berichten',
	IMPERSONATE: 'Impersonating',
	NEWS: 'Nieuws',
	NEW_CONVERSATION: 'Nieuwe melding',
	PROFILE: 'Profiel',
	PROJECT_OVERVIEW: 'Portfolio',
	REGISTER: 'Account activeren',
	RESET_PASSWORD: 'Wachtwoord herstellen',
	SESSION_EXPIRED: 'De actieve sessie is beeindigd',
	SESSION_TIMEOUT: 'De actieve sessie eindigt binnenkort',
	TERMS_CONDITIONS: 'Algemene voorwaarden',
	UPDATE_PASSWORD: 'Wijzig je wachtwoord',
	NOT_FOUND: '404 - Niet gevonden',
});
