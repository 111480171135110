// Imports => Constants
import { KEYS } from '@constants';

export const Translations = [
	{ nl: 'EUR', en: 'EUR' },
	{ nl: 'US$', en: 'US$' },
	{ nl: 'EU', en: 'EU' },
	{ nl: 'US', en: 'US' },
	{
		nl: 'Update downloaden',
		en: 'Download update',
	},
	{
		nl: 'Wachtwoord herstellen',
		en: 'Reset password',
	},
	{
		nl: 'Wachtwoord resetten',
		en: 'Reset password',
	},
	{
		nl: 'Wachtwoord vergeten?',
		en: 'Forgot your password?',
	},
	{
		nl: 'Wachtwoord vergeten',
		en: 'Forgot your password',
	},
	{
		nl: 'Terug naar inloggen',
		en: 'Back to login',
	},
	{
		nl: 'Terug naar Portfolio',
		en: 'Back to Portfolio',
	},
	{
		nl: 'Terug naar portfolio',
		en: 'Back to portfolio',
	},
	{
		nl: 'Lopende projecten',
		en: 'Current projects',
	},
	{
		nl: 'Afgeronde projecten',
		en: 'Finished projects',
	},
	{
		nl: 'Projectintroductie',
		en: 'Project introduction',
	},
	{
		nl: 'Projectupdates',
		en: 'Project Updates',
	},
	{
		nl: 'Inloggen',
		en: 'Login',
	},
	{
		nl: 'Gebruikersnaam',
		en: 'Username',
	},
	{
		nl: 'Uw gebruikersnaam',
		en: 'Your username',
	},
	{
		nl: 'Wachtwoord',
		en: 'Password',
	},
	{
		nl: 'Wachtwoord opslaan',
		en: 'Save password',
	},
	{ nl: 'Bevestig uw nieuwe wachtwoord', en: 'Repeat your new password' },
	{
		nl: '<p>Een sterk wachtwoord voorkomt dat onbevoegden toegang krijgen tot uw Mijn REALIANCE account.</p>',
		en: '<p>A strong password prevents unauthorized access to your My REALIANCE account.</p>',
	},
	{
		nl: 'Het bestand "<strong>{{filename}}</strong>" wordt opgehaald',
		en: 'Fetching file "<strong>{{filename}}</strong>"',
	},
	{ nl: 'Het bestand wordt opgehaald', en: 'Fetching file' },
	{
		nl: 'Download voltooid',
		en: 'Download complete',
	},
	{
		nl: 'Het bestand "<strong>{{filename}}</strong>" is gedownload',
		en: 'The file "<strong>{{filename}}</strong> has been downloaded',
	},
	{
		nl: 'Er is iets fout gegaan tijdens het downloaden van het bestand',
		en: 'Something has gone wrong while downloading the file.',
	},
	{
		nl: 'Aan het laden...',
		en: 'Loading...',
	},
	{
		nl: 'Projecten',
		en: 'Projects',
	},
	{
		nl: 'Totaal vermogen',
		en: 'Total equity',
	},
	{
		nl: 'Totaal uitkeringen',
		en: 'Total distributed',
	},
	{
		nl: 'Origineel vermogen',
		en: 'Original equity',
	},
	{
		nl: 'Totaal uitgekeerd',
		en: 'Total distributed',
	},
	{
		nl: 'Project beschrijving',
		en: 'Project description',
	},
	{
		nl: 'Projectbeschrijving',
		en: 'Project description',
	},
	{
		nl: 'Kerngegevens',
		en: 'Key data',
	},
	{
		nl: 'Status',
		en: 'Status',
	},
	{
		nl: 'Updates',
		en: 'Updates',
	},
	{
		nl: 'Project updates',
		en: 'Project updates',
	},
	{
		nl: '<p>Vul uw gebruikersnaam in. Wanneer deze bekend is, zal naar het e-mailadres dat bij REALIANCE bekend is een link worden gestuurd om uw wachtwoord te resetten.</p><p>Mocht u uw gebruikersnaam vergeten zijn kunt u contact opnemen met REALIANCE;<br/><a href="tel:0202103180">020 210 31 80</a> of <a href="mailto:invest@realiance.nl">invest@realiance.nl</a>.</p>',
		en: '<p>Fill in your username. If this is valid & known, a link to reset your password will be sent to the email address known to REALIANCE.<p><p>If you have forgotten your username, please contact REALIANCE;<br/><a href="tel:0202103180">020 210 31 80</a> or <a href="mailto:invest@realiance.nl">invest@realiance.nl</a>.</p>',
	},
	{
		nl: 'Profiel',
		en: 'Profile',
	},
	{
		nl: 'Documenten',
		en: 'Documents',
	},
	{
		nl: 'Overzicht',
		en: 'Overview',
	},
	{
		nl: 'Dashboard',
		en: 'Dashboard',
	},
	{
		nl: 'Jouw dashboard',
		en: 'Your dashboard',
	},
	{
		nl: 'Al het nieuws op 1 plek',
		en: 'All news in one place',
	},
	{
		nl: 'Portfolio',
		en: 'Portfolio',
	},
	{
		nl: 'Nieuws',
		en: 'News',
	},
	{
		nl: 'FAQ',
		en: 'FAQ',
	},
	{
		nl: 'Veelgestelde vragen & antwoorden',
		en: 'Frequently asked questions & answers',
	},
	{
		nl: 'Bekijk update',
		en: 'View update',
	},
	{
		nl: 'Bekijk project',
		en: 'View project',
	},
	{
		nl: `Voor verdere vragen kunt u contact opnemen met <strong>REALIANCE</strong> op
            <a href="tel:${KEYS.SUPPORT_PHONENUMBER}">${KEYS.SUPPORT_PHONENUMBER}</a> of <a href="mailto:${KEYS.SUPPORT_EMAIL_ADDRESS}">${KEYS.SUPPORT_EMAIL_ADDRESS}</a>`,
		en: `For further questions, please contact <strong>REALIANCE</strong> at
             <a href="tel:${KEYS.SUPPORT_PHONENUMBER}">${KEYS.SUPPORT_PHONENUMBER}</a> or <a href="mailto:${KEYS.SUPPORT_EMAIL_ADDRESS}">${KEYS.SUPPORT_EMAIL_ADDRESS}</a>`,
	},
	{
		nl: 'Verder lezen',
		en: 'Continue reading',
	},
	{
		nl: 'Prognose einddatum IM',
		en: 'Projected end date IM',
	},
	{
		nl: 'Prognose einddatum',
		en: 'Projected end date IM',
	},
	{
		nl: 'Prognose einddatum IM',
		en: 'Projected end date',
	},
	{
		nl: 'Totaal uitgekeerd',
		en: 'Total distributed',
	},
	{
		nl: 'Aantal uitkeringen',
		en: 'Number of distributions',
	},
	{
		nl: 'Prognose totaalrendement',
		en: 'Projected total return',
	},
	{
		nl: 'Prognose rendement (ROI per jaar)',
		en: 'Projected return (ROI per year)',
	},
	{
		nl: 'Mijn deelname',
		en: 'My participation',
	},
	{
		nl: 'Lokale partner',
		en: 'Local partner',
	},

	{
		nl: 'Einddatum',
		en: 'End date',
	},

	{
		nl: 'Startdatum',
		en: 'Start date',
	},
	{
		nl: 'Bruto totaalrendement',
		en: 'Gross total return',
	},
	{
		nl: 'Bruto rendement (ROI per jaar)',
		en: 'Gross return (ROI per year)',
	},
	{
		nl: 'Documenten: {{label}}',
		en: 'Documents: {{label}}',
	},
	{
		nl: 'Locatie',
		en: 'Location',
	},
	{
		nl: 'Locaties',
		en: 'Locations',
	},
	{
		nl: 'Omschrijving',
		en: 'Description',
	},
	{
		nl: 'Geplaatst eigen vermogen',
		en: 'Placed equity',
	},
	{
		nl: 'Geplaatst aantal participaties',
		en: 'Number of participations placed',
	},
	{
		nl: 'Prognose bruto rendement (ROI per jaar)',
		en: 'Projected gross return (ROI per year)',
	},
	{
		nl: 'Prognose (bruto) rendement (ROI per jaar)',
		en: 'Projected (gross) return (ROI per year)',
	},
	{
		nl: 'Prognose bruto totaalrendement',
		en: 'Projected gross total return',
	},
	{
		nl: 'Prognose (bruto) totaalrendement',
		en: 'Projected (gross) total return',
	},
	{
		nl: 'Visuele tijdlijn',
		en: 'Visual Timeline',
	},
	{
		nl: 'Visuele Tijdlijn',
		en: 'Visual Timeline',
	},
	{
		nl: 'Completed',
		en: 'Completed',
	},
	{
		nl: 'Afgerond',
		en: 'Completed',
	},
	{
		nl: 'Jouw profiel & instellingen',
		en: 'Your profile & settings',
	},
	{
		nl: 'Jouw gegevens',
		en: 'Your details',
	},
	{
		nl: 'Jouw accounts',
		en: 'Your accounts',
	},
	{ nl: 'Lopend', en: 'Ongoing' },
	{ nl: 'Lopend project', en: 'Ongoing project' },
	{ nl: 'Afgerond', en: 'Completed' },
	{ nl: 'Afgerond project', en: 'Completed project' },
	{ nl: 'Nieuw', en: 'New' },
	{ nl: 'Nieuw project', en: 'New project' },
	{ nl: 'Onbekend', en: 'Unknown' },
	{ nl: 'Aanhef', en: 'Salutation' },
	{ nl: 'Initialen', en: 'Initials' },
	{ nl: 'Voornaam', en: 'First name' },
	{ nl: 'Achternaam', en: 'Last name' },
	{ nl: 'Telefoonnummer', en: 'Phonenumber' },
	{ nl: 'Gebruikersnaam', en: 'Username' },
	{ nl: 'E-mailadres', en: 'Email address' },
	{ nl: 'Taal', en: 'Language' },
	{ nl: 'Documenten', en: 'Documents' },
	{ nl: 'Profiel', en: 'Profile' },
	{ nl: 'Account', en: 'Account' },
	{ nl: 'Profiel & instellingen', en: 'Profile & settings' },
	{ nl: 'Profiel & Instellingen', en: 'Profile & Settings' },
	{ nl: 'Persoonlijke gegevens', en: 'Personal details' },
	{
		nl: 'Wijzig je wachtwoord',
		en: 'Change your password',
	},
	{
		nl: 'Wachtwoord wijzigen',
		en: 'Change your password',
	},
	{
		nl: 'Totaalrendement (ROI per jaar)',
		en: 'Total return (ROI per year)',
	},
	{
		nl: 'Bruto totaalrendement (ROI per jaar)',
		en: 'Gross total return (ROI per year)',
	},
	{
		nl: 'Bruto totaalrendement',
		en: 'Gross total return',
	},
	{
		nl: 'Totaalrendement',
		en: 'Total return',
	},
	{
		nl: 'Zoek op project naam',
		en: 'Find by project name',
	},
	{
		nl: 'Geen projecten gevonden om weer te geven',
		en: 'No projects found to display',
	},
	{
		nl: 'Geen project updates gevonden om weer te geven',
		en: 'No projectupdates found to display',
	},
	{
		nl: 'Geen projectupdates gevonden om weer te geven',
		en: 'No projectupdates found to display',
	},
	{ nl: 'Alle accounts', en: 'All accounts' },
	{
		nl: 'We zijn deze pagina kwijt...',
		en: 'We have lost this page...',
	},
	{
		nl: '404 - Niet gevonden',
		en: '404 - Not found',
	},
	{
		nl: 'Sorry, de pagina die je probeerde te bezoeken bestaat niet (meer) of is verplaatst.',
		en: "Sorry, the page you are looking for doesn't exist or has been moved",
	},
	{
		nl: 'Gepubliceerd op {{date}}',
		en: 'Published on {{date}}',
	},
	{ nl: 'Bijlagen', en: 'Attachments' },
	{ nl: 'Je bent ingelogd als', en: 'You are impersonating' },
	{
		nl: 'De huidige sessie is beeindigd.',
		en: 'The current session has been terminated.',
	},
	{
		nl: 'Wegens inactiviteit ben je automatisch uitgelogd en is de actieve sessie beëindigd.',
		en: 'Due to inactivity, you are automatically logged out and the active session has ended.',
	},
	{
		nl: 'Er is iets fout gegaan tijdens het downloaden van het bestand',
		en: 'Something went wrong while downloading the file',
	},
	{
		nl: 'Probeer het opnieuw of neem contact op met <em>invest@realiance.nl</em>',
		en: 'Please try again or contact <em>invest@realiance.nl</em>',
	},
	{
		nl: 'Geen nieuwsberichten gevonden om weer te geven',
		en: 'No news articles found to display',
	},
	{
		nl: 'Geen vragen gevonden om weer te geven',
		en: 'No questions found to display',
	},
	{
		nl: 'Uitkeringen',
		en: 'Benefits',
	},
	{
		nl: 'Uitkeringen in {{year}}',
		en: 'Benefits in {{year}}',
	},
	{
		nl: 'Datum',
		en: 'Date',
	},
	{
		nl: 'Uitgekeerd',
		en: 'Paid out',
	},

	{
		nl: 'Uitloggen',
		en: 'Log out',
	},
	{
		nl: 'Belastingen',
		en: 'Taxes',
	},
	{ nl: 'Bedrag', en: 'Amount' },
	{
		nl: '<p>U bent enige tijd inactief geweest.</p><p class="h-margin-y-15">Voor uw en onze veiligheid loggen we u automatisch uit over <br/><strong>{{remaining}} {{unit}}</strong>.</p><p>Wilt u ingelogd blijven en doorgaan?</p>',
		en: '<p>You have been inactive for some time.</p><p class="h-margin-y-15">For your and our safety, we will automatically log you out in <br/><strong>{{remaining}} {{unit}}</strong>.</p><p>Do you want to stay logged in and continue?</p>',
	},
	{
		nl: '<p>Wegens inactiviteit bent u automatisch uitgelogd en is de actieve sessie beëindigd.</p><p>Log opnieuw in als u verder wilt gaan.</p>',
		en: '<p>Due to inactivity, you have been automatically logged out and the active session has ended.</p><p>Log in again if you wish to continue.</p>',
	},

	{
		nl: 'Nee, beeindig mijn sessie',
		en: 'No, end my session',
	},
	{
		nl: 'Ja, ik wil ingelogd blijven',
		en: 'Yes, I want to stay logged in',
	},
	{
		nl: 'De actieve sessie eindigt binnenkort',
		en: 'The active session will end soon',
	},
	{ nl: 'Gebruikersnaam', en: 'Username' },
	{ nl: 'Wachtwoord', en: 'Password' },
	{ nl: 'Dit is een verplicht veld', en: 'This is a required field' },
	{
		nl: 'Klik om te scrollen naar "{{title}}"',
		en: 'Click to scroll to "{{title}}"',
	},
	{
		nl: 'Klik om terug te gaan naar boven',
		en: 'Click to return to the top of the page',
	},
	{ nl: 'Klik om open of dicht te klappen', en: 'Click to open or close' },
	{
		nl: 'Klik om de taal te wijzigen naar "{{language}}"',
		en: 'Click to change the language to "{{language}}"',
	},
	{ nl: '{{number}} bijlagen', en: '{{number}} attachments' },
	{ nl: '{{number}} bijlage', en: '{{number}} attachment' },
	{ nl: '{{number}} documenten', en: '{{number}} documents' },
	{ nl: '{{number}} document', en: '{{number}} document' },
	{
		nl: 'U hebt toegang tot de volgende accounts',
		en: 'You have access to the following accounts',
	},
	{
		nl: 'Nederlands (NL)',
		en: 'Dutch (NL)',
	},
	{
		nl: 'Deutsch (DE)',
		en: 'German (DE)',
	},
	{
		nl: 'English (EN)',
		en: 'Engelish (EN)',
	},
	{ nl: 'Mevrouw', en: 'Ms.' },
	{ nl: 'mevrouw', en: 'Ms.' },
];

export default Translations;
