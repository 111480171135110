// Imports => Constants
import { ENDPOINTS } from '@constants';

// Imports => Utilities
import { AcMemoize } from '@utils';

export class ProjectsAPI {
	constructor(Instance) {
		this.Store = Instance.Store;
		this.Client = Instance.Client;
	}

	index = AcMemoize((params) => {
		return this.Client.get(ENDPOINTS.PROJECTS.INDEX, { params }).then(
			(response) => response.data
		);
	});

	show = AcMemoize((id) => {
		return this.Client.get(ENDPOINTS.PROJECTS.SHOW(id)).then(
			(response) => response.data
		);
	});

	kpis = AcMemoize((id) => {
		return this.Client.get(ENDPOINTS.PROJECTS.KPIS(id)).then(
			(response) => response.data
		);
	});

	introductions = AcMemoize(() => {
		return this.Client.get(ENDPOINTS.PROJECTS.INTRODUCTIONS).then(
			(response) => response.data
		);
	});

	updates = AcMemoize((id, draft = false) => {
		let endpoint = ENDPOINTS.PROJECTS.UPDATES(id);

		if (draft) {
			endpoint += `?draft=${draft}`;
		}

		return this.Client.get(endpoint).then((response) => {
			return response.data.data;
		});
	});
}

export default ProjectsAPI;
