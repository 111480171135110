import { AcLockObject } from '@utils/ac-lock-object';

const API = '/api';
const ACCOUNT = '/account';
const AUTH = '/auth';
const DOCUMENTS = '/documents';
const DOCUMENT = '/document';
const DOWNLOAD = '/download';
const FAQ = '/faq';
const FORGOT_PASSWORD = '/forgot';
const IMPERSONATE = '/impersonate';
const INTRODUCTIONS = '/project-introductions';
const KPIS = '/kpis';
const LANGUAGE = '/language';
const LOGIN = '/login';
const LOGOUT = '/logout';
const MARK_AS_READ = '/mark-as-read';
const MEDIA = '/media';
const NEWS = '/news';
const OAUTH = '/oauth';
const PORTAL = '/portal';
const PROFILE = '/profile';
const PROJECTS = '/projects';
const PROJECT_UPDATE = '/project-update';
const PROJECT_UPDATES = '/project-updates';
const REGISTER = '/register';
const RESET_PASSWORD = '/reset';
const TOKEN = '/token';
const UPDATE_PASSWORD = '/password';
const WHO_AM_I = '/who-am-i';

// V1
export const ENDPOINTS = AcLockObject({
	OAUTH: {
		FORGOT_PASSWORD: `${API}${AUTH}${FORGOT_PASSWORD}`, // POST
		LOGIN: `${API}${AUTH}${LOGIN}`, // POST
		LOGOUT: `${API}${LOGOUT}`, // POST
		RESET_PASSWORD: `${API}${AUTH}${RESET_PASSWORD}`, // POST
		REGISTER: `${API}${AUTH}${REGISTER}`, // POST
		IMPERSONATE: (_id) => `${API}${AUTH}${IMPERSONATE}/${_id}`, // GET
		LOGOUT: `${API}${AUTH}${LOGOUT}`, // GET
	},
	DOCUMENTS: {
		DOWNLOAD: (_id) => `${API}${DOCUMENTS}/${_id}`, // GET
	},
	FAQ: {
		INDEX: `${API}${FAQ}`, // GET
	},
	FILES: {
		INDEX: `${API}${DOCUMENTS}`, // GET
		DOWNLOAD: (_id) => `${API}${MEDIA}/${_id}${DOWNLOAD}`, // GET
	},
	KPIS: {
		INDEX: `${API}${KPIS}`, // GET
	},
	NEWS: {
		INDEX: `${API}${NEWS}`, // GET
		SHOW: (_id) => `${API}${NEWS}/${_id}`, // GET
	},
	PROFILE: {
		WHOAMI: `${API}${PROFILE}${WHO_AM_I}`, // GET
		UPDATE_PASSWORD: `${API}${AUTH}${UPDATE_PASSWORD}`, // POST
		UPDATE_LANGUAGE: `${API}${PROFILE}${LANGUAGE}`, // POST
		UPDATE_ACCOUNT: `${API}${PROFILE}${ACCOUNT}`, // POST
	},
	PROJECTS: {
		INDEX: `${API}${PROJECTS}`, // GET
		SHOW: (_id) => `${API}${PROJECTS}/${_id}`, // GET
		KPIS: (_id) => `${API}${PROJECTS}/${_id}${KPIS}`, // GET
		INTRODUCTIONS: `${API}${INTRODUCTIONS}`, // GET
	},
	PROJECT_UPDATES: {
		INDEX: `${API}${PROJECT_UPDATES}`, // GET
		DOWNLOAD: (_id) => `${API}${PROJECT_UPDATE}${DOWNLOAD}/${_id}`, // GET
	},
});

export default ENDPOINTS;
