import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import { AcIsSet, AcGetState, AcSaveState } from '@utils';

// Imports => Assets
import { Translations } from '@assets/locales/translations';

const resources = { en: { translation: {} }, nl: { translation: {} } };

const collection = Translations;
const len = collection.length;
let n = 0;

for (n; n < len; n++) {
  const item = collection[n];

  resources.en.translation[item.nl] = item.en;
  resources.nl.translation[item.nl] = item.nl;
}

// Default language setting
const getLanguage = () => {
  const locale = AcGetState(KEYS.LOCALE);

  if (AcIsSet(locale)) return locale;

  const fallback = i18n?.options?.lng || 'nl';

  AcSaveState(KEYS.LOCALE, fallback);
  return fallback;
};

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: ['nl', 'en'],
    supportedLngs: ['nl', 'en'],
    lng: getLanguage(),
    debug: false,
    preload: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
