// Imports => React
import React, { useEffect, useState, useMemo, useRef, memo } from 'react';
import loadable from '@loadable/component';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import {
	Routes,
	Route,
	Navigate,
	useLocation,
	useNavigate,
	matchRoutes,
} from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import clsx from 'clsx';

// Imports => SCSS
import '@styles/index.scss';

// Imports => Config
import config from '@config';

// Imports => Constants
import {
	AUTHENTICATION_ROUTES,
	DASHBOARD_ROUTES,
	DEFAULT_ROUTE,
	ICONS,
	KEYS,
	NOT_FOUND_ROUTE,
	PATHS,
	PERMISSIONS,
	REDIRECT_ROUTE,
	ROLES,
	ROUTES,
	THEMES,
	TITLES,
} from '@constants';

// Imports => Utilities
import { AcIsSet, AcSetDocumentTitle } from '@utils';

// Imports => Molecules
const AcToasterHoc = loadable(() =>
	import('@molecules/ac-toaster-hoc/ac-toaster-hoc.web')
);
const AcModal = loadable(() => import('@molecules/ac-modal/ac-modal.web'));
const AcTimeoutNoticeModal = loadable(() =>
	import('@molecules/ac-timeout-notice-modal/ac-timeout-notice-modal.web')
);

// Imports => Components
const AcImpersonatingNotice = loadable(() =>
	import('@components/ac-impersonating-notice/ac-impersonating-notice.web')
);
const AcHeader = loadable(() => import('@components/ac-header/ac-header.web'));
const AcActivityMonitor = loadable(() =>
	import('@components/ac-activity-monitor/ac-activity-monitor.web')
);
const AcFooter = loadable(() => import('@components/ac-footer/ac-footer.web'));
const AcStickyBar = loadable(() =>
	import('@components/ac-sticky-bar/ac-sticky-bar.web')
);

// Imports => Atoms
const AcErrorBoundary = loadable(() =>
	import('@atoms/ac-error-boundary/ac-error-boundary.web')
);
const AcPrivateRoute = loadable(() =>
	import('@atoms/ac-private-route/ac-private-route.web')
);
const AcIcon = loadable(() => import('@atoms/ac-icon/ac-icon.web'));
const AcRipple = loadable(() => import('@atoms/ac-ripple/ac-ripple.web'));
const AcAuthBackground = loadable(() =>
	import('@atoms/ac-auth-background/ac-auth-background.web')
);
const AcScrollToTop = loadable(() =>
	import('@atoms/ac-scroll-to-top/ac-scroll-to-top.web')
);

const _CLASSES = {
	ROOT: 'ac-root',
	MAIN: 'ac-app',
	UNAUTHORIZED: 'ac-app--unauthorized',
	IMPERSONATING: 'ac-app--impersonating',
	SCROLLER: 'ac-scroller',
	ROUTE: {
		SECTION: 'ac-route__section',
		AUTHORIZED: 'ac-route__section--authorized',
		HIDDEN: 'ac-route__section--hidden',
	},
};

const App = ({ store }) => {
	const $monitor = useRef(null);
	const $nodeRef = useRef(null);

	const location = useLocation();
	const navigate = useNavigate();

	const [scrollToTop, setScrollToTop] = useState(false);
	const [ready, setReady] = useState(false);
	const [showFreshContentNotice, setShowFreshContentNotice] = useState(false);

	const {
		auth: { is_authorized, is_impersonating },
		conversations,
		profile,
		ui,
		freshContentIsAvailable,
	} = store;

	useEffect(() => {
		handleRouteChanged();
		addEvents();

		return () => removeEvents();
	}, [location]);

	useEffect(() => {
		if (freshContentIsAvailable) setShowFreshContentNotice(true);
	}, [freshContentIsAvailable]);

	useEffect(() => {
		window.addEventListener('scroll', () => {
			if (window.pageYOffset > 340) {
				setScrollToTop(true);
			} else {
				setScrollToTop(false);
			}
		});
	}, []);

	const impersonatingIsReady = useMemo(() => {
		if (!is_impersonating) return false;
		if (!AcIsSet(profile?.current_profile)) return false;

		return AcIsSet(profile?.current_profile);
	}, [is_impersonating, profile?.current_profile]);

	const addEvents = () => {
		if (!is_authorized) return;
		removeEvents().then(() => {
			document.body.addEventListener('keyup', handleKeyUp, { passive: true });
		});
	};

	const removeEvents = () => {
		return new Promise((resolve) => {
			document.body.removeEventListener('keyup', handleKeyUp);
			resolve();
		});
	};

	const handleKeyUp = async (event) => {
		const key = event.key || event.which;

		const $active_element = document.activeElement;
		const $inputs = ['input', 'select', 'button', 'textarea'];

		if (key && key === 'Enter' && event.ctrlKey) {
			const $button = document.querySelector('button[rel="ac-send-reply-button"]');
			if (AcIsSet($button) && $button.click) $button.click();
		} else if (
			!$active_element ||
			$inputs.indexOf($active_element.tagName.toLowerCase()) === -1
		) {
			if (key) {
				switch (key) {
					case '/':
					case 191:
						const $query = document.querySelector('input[name="query"]');
						if (AcIsSet($query) && $query.focus) $query.focus();
						break;

					case 'c':
					case 67:
						if (!ui.current_modal.visible) {
							const $button = document.querySelector(
								'button[rel="ac-create-button"]'
							);
							if (AcIsSet($button) && $button.click) $button.click();
						}
						break;

					default:
				}
			}
		}
	};

	const handleRouteChanged = (event) => {
		if (
			ui.current_modal &&
			ui.current_modal.type &&
			ui.current_modal.type !== 'timeout'
		) {
			ui.reset(KEYS.MODAL);
		}

		ui.reset(KEYS.SUB_NAVIGATION);
		ui.reset(KEYS.STICKY_BAR);

		const isAuthRoute = AUTHENTICATION_ROUTES.indexOf(location.pathname) > -1;

		if (is_authorized && !isAuthRoute) {
			profile.who_am_i();

			if ($monitor && $monitor.current) $monitor.current.restart();
		} else {
			if ($monitor && $monitor.current) $monitor.current.stop();
		}
	};

	const displayTimeoutNoticeModal = async (event) => {
		if (event?.preventDefault) event.preventDefault();
		if (event?.stopPropagation) event.stopPropagation();

		await ui.reset(KEYS.MODAL);
		await ui.set(KEYS.MODAL, {
			title: TITLES.SESSION_TIMEOUT,
			type: 'timeout',
			body: (
				<AcTimeoutNoticeModal
					checkActivity={$monitor && $monitor.current && $monitor.current.check}
					callback={async () => {
						if ($monitor && $monitor.current) $monitor.current.restart();
						await ui.reset(KEYS.MODAL);
					}}
					update={() => {
						ui.setValue(KEYS.MODAL, KEYS.TITLE, TITLES.SESSION_EXPIRED);
						navigate(ROUTES.LOGIN.path, { replace: true });
					}}
				/>
			),
			centered: true,
			closeable: false,
			visible: true,
			actions: [],
			callback: async () => {
				await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
			},
		});
	};

	const isNotAnAuthorizationRoute = useMemo(
		() => AUTHENTICATION_ROUTES.indexOf(location.pathname) === -1,
		[location]
	);

	const authorized = useMemo(() => {
		return is_authorized && isNotAnAuthorizationRoute;
	}, [is_authorized, location, isNotAnAuthorizationRoute]);

	const getRouteSectionClassNames = useMemo(() => {
		return clsx(
			_CLASSES.ROUTE.SECTION,
			_CLASSES.SCROLLER,
			is_authorized && _CLASSES.ROUTE.AUTHORIZED
		);
	}, [is_authorized]);

	const getMainClassNames = useMemo(() => {
		return clsx(
			_CLASSES.MAIN,
			!isNotAnAuthorizationRoute && _CLASSES.UNAUTHORIZED,
			impersonatingIsReady && _CLASSES.IMPERSONATING
		);
	}, [isNotAnAuthorizationRoute, impersonatingIsReady]);

	const renderModal = useMemo(() => {
		const { current_modal } = ui;
		const { body } = current_modal;

		return (
			<AcModal {...current_modal} callback={current_modal.callback}>
				{body}
			</AcModal>
		);
	}, [
		ui.current_modal,
		ui.current_modal.body,
		ui.current_modal.title,
		ui.current_modal.closeable,
		ui.current_modal.visible,
	]);

	const renderToasterHoc = useMemo(() => {
		return (
			<AcToasterHoc queue={store.toasters.queue} callback={store.toasters.remove} />
		);
	}, [store.toasters, store.toasters.queue]);

	const getAvailableRoutes = useMemo(() => {
		const collection = ROUTES;
		let routes = [];
		let key;

		for (key in collection) {
			const item = collection[key];
			const { forbidden, allowed, locale, render } = item;

			if (forbidden && !is_authorized) continue;
			if (AcIsSet(locale) && locale !== profile.current_language) continue;
			else routes.push(item);
		}

		return routes;
	}, [location, profile?.current_language, is_authorized]);

	const renderDefaultRoute = useMemo(() => {
		if (!authorized) {
			return (
				<Route path={'*'} element={<Navigate to={REDIRECT_ROUTE.path} replace />} />
			);
		}

		const route = DEFAULT_ROUTE;

		return (
			<Route
				key={`default-route-${route.id}`}
				path={'/'}
				element={
					<AcPrivateRoute
						name={route.name}
						path={route.path}
						component={route.component}
						forbidden={route.forbidden}
						authorized={is_authorized}
					/>
				}
			/>
		);
	}, [authorized]);

	const renderRoutes = useMemo(() => {
		const collection = getAvailableRoutes;
		let result = [];
		let key;

		for (key in collection) {
			const item = collection[key];
			const { forbidden, allowed, locale, render } = item;

			if (forbidden && !is_authorized) continue;
			if (AcIsSet(locale) && locale !== profile.current_language) continue;
			else {
				const object = (
					<Route
						key={`route-${item.id}`}
						path={item.path}
						element={
							<AcPrivateRoute
								name={item.name}
								path={item.path}
								component={item.component}
								forbidden={item.forbidden}
								authorized={is_authorized}
							/>
						}
					/>
				);
				result.push(object);
			}
		}

		return result;
	}, [
		is_authorized,
		authorized,
		location,
		profile.current_language,
		getAvailableRoutes,
	]);

	const getActiveRoute = useMemo(() => {
		const routes = getAvailableRoutes;

		const collection = matchRoutes(routes, location);

		let route = null;

		if (AcIsSet(collection) && collection.length) {
			route = collection[0]?.route;

			if (route?.parent && location.pathname.indexOf(route?.parent) > -1) {
				route = { path: route?.parent };
			}
		}

		if (!AcIsSet(route?.path)) return null;

		return route.path;
	}, [getAvailableRoutes, location]);

	const renderNotFoundRoute = useMemo(() => {
		if (!authorized || location.pathname === PATHS.NOT_FOUND) return null;
		if (getActiveRoute) return null;
		if (!renderRoutes?.length) return null;

		return (
			<Route
				path={'*'}
				errorElement
				element={<AcPrivateRoute component={ROUTES.NOT_FOUND.component} />}
			/>
		);
	}, [authorized, location, getActiveRoute]);

	const renderHeader = useMemo(() => {
		return <AcHeader />;
	}, []);

	const renderFooter = useMemo(() => {
		return <AcFooter />;
	}, []);

	const renderStickyBar = useMemo(() => {
		if (!authorized) return null;
		if (!store.ui?.current_sticky_bar?.visible) return null;

		return <AcStickyBar />;
	}, [authorized, store.ui?.current_sticky_bar?.visible]);

	const renderAuthBackground = useMemo(() => {
		if (authorized) return null;
		return <AcAuthBackground authorized={authorized} />;
	}, [authorized]);

	const renderFreshContentNotice = useMemo(() => {
		if (!freshContentIsAvailable) return null;
		if (!showFreshContentNotice) return null;

		return (
			<div
				className={'ac-fresh-content-notice'}
				onClick={() => window.location.reload(true)}
			>
				<p>
					Er is een nieuwe versie van de applicatie beschikbaar!
					<br />
					Klik hier om de applicatie te verversen.
				</p>
			</div>
		);
	}, [showFreshContentNotice, freshContentIsAvailable]);

	return (
		<AcErrorBoundary screen={location.pathname}>
			{renderAuthBackground}

			<main className={getMainClassNames} key={authorized} role={'main'}>
				<section
					id={KEYS.SCROLLER}
					className={getRouteSectionClassNames}
					node={() => setReady(true)}
				>
					<ReactCSSTransitionReplace
						transitionName='fade-wait'
						transitionEnterTimeout={300}
						transitionLeaveTimeout={200}
					>
						<div key={location.key}>
							<Routes location={location}>
								{renderRoutes}
								{renderDefaultRoute}
								{renderRoutes && renderNotFoundRoute}
							</Routes>
						</div>
					</ReactCSSTransitionReplace>
				</section>

				{authorized && <AcImpersonatingNotice />}

				{authorized && renderHeader}

				{authorized && renderFooter}

				{authorized && renderStickyBar}

				{authorized && renderModal}

				{renderToasterHoc}

				{authorized && (
					<AcActivityMonitor
						ref={$monitor}
						callback={displayTimeoutNoticeModal}
						authorized={authorized}
					/>
				)}

				{authorized && <AcScrollToTop visible={scrollToTop} />}

				{showFreshContentNotice && renderFreshContentNotice}
			</main>
		</AcErrorBoundary>
	);
};

export default withStore(observer(App));
