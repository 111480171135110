import { AcLockObject } from '@utils/ac-lock-object';

export const ICONS = AcLockObject({
	IMAGE_FILTER_CENTER_FOCUS_WEAK: 'image-filter-center-focus-weak',
	LOUPE: 'loupe',
	ACCOUNT_REACTIVATE_OUTLINE: 'account-reactivate-outline',
	FILTER_VARIANT: 'filter-variant',
	ACCOUNT: 'account',
	ACCOUNT_CIRCLE_OUTLINE: 'account-circle-outline',
	ACCOUNT_EDIT_OUTLINE: 'account-edit-outline',
	ACCOUNT_OUTLINE: 'account-outline',
	ALERT_CIRCLE_OUTLINE: 'alert-circle-outline',
	ALERT_OCTAGON_OUTLINE1: 'alert-octagon-outline1',
	ALERT_OCTAGON_OUTLINE: 'alert-octagon-outline',
	ALERT_OUTLINE1: 'alert-outline1',
	ALERT_OUTLINE: 'alert-outline',
	APPS: 'apps',
	ARROW_ALL: 'arrow-all',
	ARROW_BOTTOM_LEFT: 'arrow-bottom-left',
	ARROW_BOTTOM_RIGHT: 'arrow-bottom-right',
	ARROW_COMPRESS: 'arrow-compress',
	ARROW_COMPRESS_ALL: 'arrow-compress-all',
	ARROW_DOWN: 'arrow-down',
	ARROW_EXPAND: 'arrow-expand',
	ARROW_EXPAND_ALL: 'arrow-expand-all',
	ARROW_LEFT: 'arrow-left',
	ARROW_RIGHT: 'arrow-right',
	ARROW_TOP_LEFT: 'arrow-top-left',
	ARROW_TOP_RIGHT: 'arrow-top-right',
	ARROW_UP: 'arrow-up',
	AT: 'at',
	NINJA: 'ninja',
	BACKBURGER: 'backburger',
	BALLOT_OUTLINE: 'ballot-outline',
	BELL: 'bell',
	BELL_OUTLINE: 'bell-outline',
	CALENDAR_BLANK: 'calendar-blank',
	CAR: 'car',
	CELLPHONE: 'cellphone',
	CHECK: 'check',
	CHECKBOX_MARKED_CIRCLE_OUTLINE: 'checkbox-marked-circle-outline',
	CHECK_ALL: 'check-all',
	CHECK_CIRCLE_OUTLINE: 'check-circle-outline',
	CHEVRON_DOWN: 'chevron-down',
	CHEVRON_LEFT: 'chevron-left',
	CHEVRON_RIGHT: 'chevron-right',
	CHEVRON_UP: 'chevron-up',
	CIRCLE_EDIT_OUTLINE: 'circle-edit-outline',
	CIRCLE_OUTLINE: 'circle-outline',
	CLEAR: 'clear',
	CLOCK: 'clock',
	CLOSE: 'close',
	CLOSE_BOX_OUTLINE: 'close-box-outline',
	CLOSE_CIRCLE_OUTLINE: 'close-circle-outline',
	COINS: 'coins',
	CREDIT_CARD_MULTIPLE: 'credit-card-multiple',
	DELETE_CIRCLE_OUTLINE: 'delete-circle-outline',
	DOTS_HORIZONTAL: 'dots-horizontal',
	DOTS_HORIZONTAL_CIRCLE_OUTLINE: 'dots-horizontal-circle-outline',
	DOTS_VERTICAL: 'dots-vertical',
	DOTS_VERTICAL_CIRCLE_OUTLINE: 'dots-vertical-circle-outline',
	DOWNLOAD: 'download',
	DOWNLOAD_BOX_OUTLINE: 'download-box-outline',
	DOWNLOAD_CIRCLE_OUTLINE: 'download-circle-outline',
	DUPLICATE: 'duplicate',
	EMAIL: 'email',
	EMAIL_FAST_OUTLINE: 'email-fast-outline',
	EMAIL_OUTLINE: 'email-outline',
	EXCLAMATION: 'exclamation',
	EYE_CIRCLE_OUTLINE: 'eye-circle-outline',
	EYE_OFF_OUTLINE: 'eye-off-outline',
	EYE_OUTLINE: 'eye-outline',
	EYE_SETTINGS_OUTLINE: 'eye-settings-outline',
	FACEBOOK: 'facebook',
	FILE_EXCEL_O: 'file-excel-o',
	FILE_IMAGE_O: 'file-image-o',
	FILE_O: 'file-o',
	FILE_PDF_O: 'file-pdf-o',
	FILE_POWERPOINT_O: 'file-powerpoint-o',
	FILE_PRESENTATION_BOX: 'file-presentation-box',
	FILE_SVG_O: 'file-svg-o',
	FILE_TEXT_O: 'file-text-o',
	FILE_WORD_O: 'file-word-o',
	FILTER: 'filter',
	FILTER_OUTLINE: 'filter-outline',
	FORMAT_LIST_BULLETED: 'format-list-bulleted',
	FORM_TEXTBOX_PASSWORD: 'form-textbox-password',
	FULLSCREEN: 'fullscreen',
	FULLSCREEN_EXIT: 'fullscreen-exit',
	GAMEPAD_CIRCLE_OUTLINE: 'gamepad-circle-outline',
	GOOGLE_PLUS: 'google-plus',
	GRID: 'grid',
	GRID_LARGE: 'grid-large',
	HELP: 'help',
	HELP_CIRCLE_OUTLINE: 'help-circle-outline',
	HEXAGON: 'hexagon',
	HEXAGON_OUTLINE: 'hexagon-outline',
	HOME: 'home',
	HOME_CIRCLE_OUTLINE: 'home-circle-outline',
	IMAGE_OFF_OUTLINE: 'image-off-outline',
	IMAGE_OUTLINE: 'image-outline',
	IMAGE_REMOVE_OUTLINE: 'image-remove-outline',
	INFORMATION_OUTLINE: 'information-outline',
	INFORMATION_VARIANT_CIRCLE_OUTLINE: 'information-variant-circle-outline',
	INSTAGRAM: 'instagram',
	LINKEDIN: 'linkedin',
	LOCK_RESET: 'lock-reset',
	LOGIN_VARIANT: 'login-variant',
	LOGOUT: 'logout',
	LOGOUT_VARIANT: 'logout-variant',
	MAGNIFY: 'magnify',
	MAGNIFY_EXPAND: 'magnify-expand',
	MAGNIFY_MINUS_OUTLINE: 'magnify-minus-outline',
	MAGNIFY_PLUS_OUTLINE: 'magnify-plus-outline',
	MAGNIFY_SCAN: 'magnify-scan',
	UNAVAILABLE: 'magnify-scan',
	MAIL_RU: 'mail-ru',
	MAP_MARKER: 'map-marker',
	MAP_MARKER_CIRCLE: 'map-marker-circle',
	MAP_MARKER_OUTLINE: 'map-marker-outline',
	MENU: 'menu',
	MINUS: 'minus',
	MONITOR: 'monitor',
	MOTION_PLAY: 'motion-play',
	MOTION_PLAY_OUTLINE: 'motion-play-outline',
	MOUSE_OUTLINE: 'mouse-outline',
	NEWSPAPER_O: 'newspaper-o',
	OPEN_IN_NEW2: 'open-in-new2',
	OPEN_IN_NEW: 'open-in-new',
	OUTGOING_INBOX: 'outgoing-inbox',
	OUTGOING_MAIL: 'outgoing-mail',
	OVERSCAN: 'overscan',
	PANORAMA_WIDE_ANGLE: 'panorama-wide-angle',
	PAPERCLIP: 'paperclip',
	PENCIL: 'pencil',
	PENCIL_CIRCLE_OUTLINE: 'pencil-circle-outline',
	PHONE: 'phone',
	PLAY_CIRCLE: 'play-circle',
	PLAY_CIRCLE_OUTLINE: 'play-circle-outline',
	PLUS: 'plus',
	PLUS_CIRCLE_OUTLINE: 'plus-circle-outline',
	POWER: 'power',
	PROGRESS_DOWNLOAD: 'progress-download',
	PROGRESS_UPLOAD: 'progress-upload',
	PROJECTOR_SCREEN: 'projector-screen',
	RECORD_CIRCLE_OUTLINE: 'record-circle-outline',
	REFRESH: 'refresh',
	ROCKET: 'rocket',
	SECURITY_LOCK: 'security-lock',
	SEND_CIRCLE_OUTLINE: 'send-circle-outline',
	SEND_OUTLINE: 'send-outline',
	SETTINGS: 'settings',
	SETTINGS_OUTLINE: 'settings-outline',
	SHARE_VARIANT: 'share-variant',
	SHIELD_ACCOUNT: 'shield-account',
	SHIELD_ACCOUNT_OUTLINE: 'shield-account-outline',
	SHIELD_HALF_FULL: 'shield-half-full',
	SHIELD_LOCK_OUTLINE: 'shield-lock-outline',
	SIGNAL_OFF: 'signal-off',
	SQUARE_EDIT_OUTLINE: 'square-edit-outline',
	STAR: 'star',
	STAR_HALF: 'star-half',
	STAR_OUTLINE: 'star-outline',
	SUBDIRECTORY_ARROW_LEFT: 'subdirectory-arrow-left',
	SUBDIRECTORY_ARROW_RIGHT: 'subdirectory-arrow-right',
	TAG_OUTLINE: 'tag-outline',
	TEXT_BOX_OUTLINE: 'text-box-outline',
	TRAY_ARROW_DOWN: 'tray-arrow-down',
	TRAY_ARROW_UP: 'tray-arrow-up',
	TUNE: 'tune',
	TWITTER: 'twitter',
	UPLOAD: 'upload',
	VIMEO: 'vimeo',
	WATER: 'water',
	YOUTUBE_PLAY: 'youtube-play',
});
