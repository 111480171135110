// Imports => Constants
import { ENDPOINTS } from '@constants';

export class ProfileAPI {
	constructor(Instance) {
		this.Store = Instance.Store;
		this.Client = Instance.Client;
	}

	whoami = () => {
		return this.Client.get(ENDPOINTS.PROFILE.WHOAMI).then(
			(response) => response.data
		);
	};

	update_language = (data) => {
		return this.Client.post(ENDPOINTS.PROFILE.UPDATE_LANGUAGE, data).then(
			(response) => response.data
		);
	};

	update_account = (data) => {
		return this.Client.post(ENDPOINTS.PROFILE.UPDATE_ACCOUNT, data).then(
			(response) => response.data
		);
	};

	update_password = (data) => {
		return this.Client.post(ENDPOINTS.PROFILE.UPDATE_PASSWORD, data).then(
			(response) => response.data
		);
	};
}

export default ProfileAPI;
