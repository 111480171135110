// Imports => Constants
import { ENDPOINTS } from '@constants';

// Imports => Utilities
import { AcMemoize } from '@utils';

export class ProjectUpdatesAPI {
	constructor(Instance) {
		this.Store = Instance.Store;
		this.Client = Instance.Client;
		this.DownloadClient = Instance.DownloadClient;
	}

	index = AcMemoize(() => {
		return this.Client.get(ENDPOINTS.PROJECT_UPDATES.INDEX).then(
			(response) => response.data
		);
	});

	download = AcMemoize((id, type) => {
		return this.Client.get(ENDPOINTS.PROJECT_UPDATES.DOWNLOAD(id)).then(
			(response) => response
		);
	});
}

export default ProjectUpdatesAPI;
