// Imports => Vendor
import axios from 'axios';

// Imports => Interceptors
import { AcTokenRefresher } from './interceptors.api';

// Imports => API
import AuthAPI from '@api/auth.api';
import FaqAPI from '@api/faq.api';
import FilesAPI from '@api/files.api';
import KpisAPI from '@api/kpis.api';
import NewsAPI from '@api/news.api';
import ProfileAPI from '@api/profile.api';
import ProjectsAPI from '@api/projects.api';
import ProjectUpdatesAPI from '@api/project-updates.api';

const onUploadProgress = (event) => {
	console.group('[Axios] => fn.onUploadProgress');
	console.log('Event: ', event);
	console.groupEnd();
};

const onDownloadProgress = (event) => {
	console.group('[Axios] => fn.onDownloadProgress');
	console.log('Event: ', event);
	console.groupEnd();
};

let _timeOut = null;
let _errorControllers = [];

const unauthenticatedState = (state) => {
	const unauthenticatedEvent = new CustomEvent('unAuthenticate');
	window.dispatchEvent(unauthenticatedEvent);
};

const cancelRequests = () => {
	const collection = _errorControllers;
	const len = collection.length;
	let n = 0;

	for (n; n < len; n++) {
		const instance = collection[n];
		if (instance?.abort) instance.abort();
	}

	_errorControllers = [];
};

const addInterceptors = (requestClient) => {
	requestClient.interceptors.response.use(
		(response) => {
			return response;
		},
		(error) => {
			if (window?.navigator?.vibrate) window?.navigator?.vibrate?.(400);

			if (error?.response?.status === 401) {
				clearTimeout(_timeOut);

				const cancelRequestsEvent = new CustomEvent('cancelRequests');
				window.dispatchEvent(cancelRequestsEvent);

				_timeOut = setTimeout(() => unauthenticatedState(false), 1000 / 60);
			}

			return Promise.reject(error);
		}
	);

	requestClient.interceptors.request.use(async (config) => {
		// if (navigator.onLine)
		// 	await AcTokenRefresher(requestClient, unauthenticatedState);

		const controller = new AbortController();
		config.signal = controller.signal;
		_errorControllers.push(controller);

		return config;
	});
};

export class API {
	constructor(config, Store) {
		this.Store = Store;

		const Client = axios.create({
			...config.api,
		});
		addInterceptors(Client);

		const DownloadClient = axios.create({
			...config.download,
			onDownloadProgress,
		});
		addInterceptors(DownloadClient);

		const UploadClient = axios.create({
			...config.upload,
			onUploadProgress,
		});
		addInterceptors(UploadClient);

		window.addEventListener('cancelRequests', cancelRequests, false);

		this.auth = new AuthAPI({ Store, Client });
		this.faq = new FaqAPI({ Store, Client });
		this.files = new FilesAPI({ Store, Client, DownloadClient });
		this.kpis = new KpisAPI({ Store, Client });
		this.news = new NewsAPI({ Store, Client });
		this.profile = new ProfileAPI({ Store, Client });
		this.projects = new ProjectsAPI({ Store, Client });
		this.project_updates = new ProjectUpdatesAPI({ Store, Client, DownloadClient });
	}
}

export default API;
