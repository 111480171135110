import loadable from '@loadable/component';

// Brand & Logo
/* eslint react-app/import/no-webpack-loader-syntax: off */
const BRAND = loadable(() =>
	import('-!svg-react-loader!@assets/images/branding/realiance-brand-1x.svg')
);
const BRAND_X2 = loadable(() =>
	import('-!svg-react-loader!@assets/images/branding/realiance-brand-2x.svg')
);
const LOGO = loadable(() =>
	import('-!svg-react-loader!@assets/images/branding/realiance-logo-1x.svg')
);
const LOGO_WHITE = loadable(() =>
	import('-!svg-react-loader!@assets/images/branding/realiance-logo-white-1x.svg')
);
const LOGO_FULL = loadable(() =>
	import('-!svg-react-loader!@assets/images/branding/realiance-logo-full-1x.svg')
);
const LOGO_FULL_WHITE = loadable(() =>
	import(
		'-!svg-react-loader!@assets/images/branding/realiance-logo-full-white-1x.svg'
	)
);

// Wallpapers
import HERO_WALLPAPER_SM from '@assets/images/wallpapers/hero-wallpaper-sm-1x.jpg';
import HERO_WALLPAPER_MD from '@assets/images/wallpapers/hero-wallpaper-md-1x.jpg';
import HERO_WALLPAPER_LG from '@assets/images/wallpapers/hero-wallpaper-lg-1x.jpg';
import HERO_WALLPAPER_XL from '@assets/images/wallpapers/hero-wallpaper-xl-1x.jpg';

// KPI Icons
const AcKPIIcon1 = loadable(() =>
	import('-!svg-react-loader!@assets/images/kpis/kpi-icon-n1-1x.svg')
);
const AcKPIIcon2 = loadable(() =>
	import('-!svg-react-loader!@assets/images/kpis/kpi-icon-n2-1x.svg')
);
const AcKPIIcon3 = loadable(() =>
	import('-!svg-react-loader!@assets/images/kpis/kpi-icon-n3-1x.svg')
);
const AcKPIIcon4 = loadable(() =>
	import('-!svg-react-loader!@assets/images/kpis/kpi-icon-n4-1x.svg')
);
const AcKPIIcon5 = loadable(() =>
	import('-!svg-react-loader!@assets/images/kpis/kpi-icon-n5-1x.svg')
);
const AcKPIIcon6A = loadable(() =>
	import('-!svg-react-loader!@assets/images/kpis/kpi-icon-n6a-1x.svg')
);
const AcKPIIcon6B = loadable(() =>
	import('-!svg-react-loader!@assets/images/kpis/kpi-icon-n6b-1x.svg')
);
const AcKPIIcon7A = loadable(() =>
	import('-!svg-react-loader!@assets/images/kpis/kpi-icon-n7a-1x.svg')
);
const AcKPIIcon7B = loadable(() =>
	import('-!svg-react-loader!@assets/images/kpis/kpi-icon-n7b-1x.svg')
);
const AcKPIIcon8A = loadable(() =>
	import('-!svg-react-loader!@assets/images/kpis/kpi-icon-n8a-1x.svg')
);
const AcKPIIcon8B = loadable(() =>
	import('-!svg-react-loader!@assets/images/kpis/kpi-icon-n8b-1x.svg')
);
const AcKPIIcon9 = loadable(() =>
	import('-!svg-react-loader!@assets/images/kpis/kpi-icon-n9-1x.svg')
);
const AcKPIIcon10 = loadable(() =>
	import('-!svg-react-loader!@assets/images/kpis/kpi-icon-n10-1x.svg')
);
const AcKPIIcon11 = loadable(() =>
	import('-!svg-react-loader!@assets/images/kpis/kpi-icon-n11-1x.svg')
);
const AcKPIIcon12 = loadable(() =>
	import('-!svg-react-loader!@assets/images/kpis/kpi-icon-n12-1x.svg')
);
const AcKPIIcon13 = loadable(() =>
	import('-!svg-react-loader!@assets/images/kpis/kpi-icon-n13-1x.svg')
);
const AcKPIIcon14 = loadable(() =>
	import('-!svg-react-loader!@assets/images/kpis/kpi-icon-n14-1x.svg')
);
const AcKPIIcon15 = loadable(() =>
	import('-!svg-react-loader!@assets/images/kpis/kpi-icon-n15-1x.svg')
);
const AcKPIIcon16 = loadable(() =>
	import('-!svg-react-loader!@assets/images/kpis/kpi-icon-n16-1x.svg')
);
const AcKPIIcon17A = loadable(() =>
	import('-!svg-react-loader!@assets/images/kpis/kpi-icon-n17a-1x.svg')
);
const AcKPIIcon17B = loadable(() =>
	import('-!svg-react-loader!@assets/images/kpis/kpi-icon-n17b-1x.svg')
);
const AcKPIIcon18A = loadable(() =>
	import('-!svg-react-loader!@assets/images/kpis/kpi-icon-n18a-1x.svg')
);
const AcKPIIcon18B = loadable(() =>
	import('-!svg-react-loader!@assets/images/kpis/kpi-icon-n18b-1x.svg')
);
const AcKPIIcon19A = loadable(() =>
	import('-!svg-react-loader!@assets/images/kpis/kpi-icon-n19a-1x.svg')
);
const AcKPIIcon19B = loadable(() =>
	import('-!svg-react-loader!@assets/images/kpis/kpi-icon-n19b-1x.svg')
);
const AcKPIIcon20 = loadable(() =>
	import('-!svg-react-loader!@assets/images/kpis/kpi-icon-n20-1x.svg')
);
const AcKPIIcon21 = loadable(() =>
	import('-!svg-react-loader!@assets/images/kpis/kpi-icon-n21-1x.svg')
);
const AcKPIIcon22 = loadable(() =>
	import('-!svg-react-loader!@assets/images/kpis/kpi-icon-n22-1x.svg')
);

export const KPIS = {
	1: AcKPIIcon1,
	2: AcKPIIcon2,
	3: AcKPIIcon3,
	4: AcKPIIcon4,
	5: AcKPIIcon5,
	'6a': AcKPIIcon6A,
	'6b': AcKPIIcon6B,
	'7a': AcKPIIcon7A,
	'7b': AcKPIIcon7B,
	'8a': AcKPIIcon8A,
	'8b': AcKPIIcon8B,
	9: AcKPIIcon9,
	10: AcKPIIcon10,
	11: AcKPIIcon11,
	12: AcKPIIcon12,
	13: AcKPIIcon13,
	14: AcKPIIcon14,
	15: AcKPIIcon15,
	16: AcKPIIcon16,
	'17a': AcKPIIcon17A,
	'17b': AcKPIIcon17B,
	'18a': AcKPIIcon18A,
	'18b': AcKPIIcon18B,
	'19a': AcKPIIcon19A,
	'19b': AcKPIIcon19B,
	20: AcKPIIcon20,
	21: AcKPIIcon21,
	22: AcKPIIcon22,
};

export const VISUALS = {
	BRAND,
	BRAND_X2,
	LOGO,
	LOGO_WHITE,
	LOGO_FULL,
	LOGO_FULL_WHITE,
	KPIS,
	HERO_WALLPAPER_SM,
	HERO_WALLPAPER_MD,
	HERO_WALLPAPER_LG,
	HERO_WALLPAPER_XL,
};

export default {
	BRAND,
	BRAND_X2,
	LOGO,
	LOGO_WHITE,
	LOGO_FULL,
	LOGO_FULL_WHITE,
	KPIS,
	HERO_WALLPAPER_SM,
	HERO_WALLPAPER_MD,
	HERO_WALLPAPER_LG,
	HERO_WALLPAPER_XL,
};
