// Imports => Utilities
import {
  AcUUID,
  AcIsSet,
  AcIsEmptyString,
  AcGetTypeOfVideo,
  AcMemoize,
} from '@utils';

export class ProjectUpdate {
  constructor(project) {
    this.project = project;

    const result = {
      ...project,
      media: this.parseMedia(project),
      files: this.parseFiles(project),
    };

    return result;
  }

  parseFiles = AcMemoize((project) => {
    const {
      attachments: { files },
    } = project;

    if (!AcIsSet(files) || !files.length) return null;

    return [...files];
  });

  parseMedia = AcMemoize((project) => {
    const {
      attachments: { images, videos },
    } = project;

    // Skip if there are no images and no videos to parse
    if (images?.length === 0 && videos?.length === 0) {
      return;
    }

    // 1. We are adding a property 'type' to images and videos
    // 2. We are filtering the videos, since somehow also non-videos can be added to this collection in the admin...
    const result = {
      images: images?.map((n) => ({ ...n, type: 'image' })),
      videos: videos
        ?.filter((n) => {
          const type = AcGetTypeOfVideo(n.url);

          return type === 'youtube' || type === 'vimeo';
        })
        .map((n) => ({ ...n, type: 'video', provider: AcGetTypeOfVideo(n.url) })),
    };

    let collection = [...result?.images, ...result?.videos];

    // Sort all media by `created_at` (uploaded), ascending
    collection = collection.sort((a, b) => {
      return new Date(a.created_at) - new Date(b.created_at);
    });

    // All done
    return collection;
  });
}
