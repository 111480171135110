// Imports => Constants
import { ENDPOINTS } from '@constants';

// Imports => Utilities
import { AcMemoize } from '@utils';

export class NewsAPI {
	constructor(Instance) {
		this.Store = Instance.Store;
		this.Client = Instance.Client;
	}

	index = AcMemoize(() => {
		return this.Client.get(ENDPOINTS.NEWS.INDEX).then((response) => response.data);
	});

	show = AcMemoize((id) => {
		return this.Client.get(ENDPOINTS.NEWS.SHOW(id)).then(
			(response) => response.data
		);
	});
}

export default NewsAPI;
