// Imports => Utilities
import { AcUUID, AcIsSet, AcIsEmptyString, AcGetTypeOfVideo } from '@utils';

// Imports => Models
import { ProjectUpdate } from '@models/ac-project-update.model';

export class Project {
  constructor(project) {
    this.project = project;

    const result = {
      _resource: project,
      id: project.id,
      status: project.status,
      name: project.name,
      content: project.content,
      date: project.date,
      city: project.city,
      state: project.state,
      country: project.country,
      region: project.region,
      currency: project.project_valuta,
      benefits: this.parseBenefits(project),
      description: this.parseDescription(project),
      figures: this.parseFigures(project),
      kpis: this.parseKPIs(project),
      timeline: this.parseStatusTimeline(project),
      keydata: this.parseKeyData(project),
      documents: this.parseDocuments(project),
      updates: this.parseUpdates(project),
      media: this.parseMedia(project),
      featured_images: this.parseFeaturedImages(project),
    };

    return result;
  }

  parseDescription = (project) => {
    if (!AcIsSet(project?.project_description)) return null;

    return {
      ...project?.project_description,
      featured_images: this.parseFeaturedImages(project),
    };
  };

  parseBenefits = (project) => {
    const { project_benefits } = project;

    if (!AcIsSet(project_benefits) || !project_benefits?.length) return null;

    return [...project_benefits];
  };

  parseFigures = (project) => {
    const {
      participations_total_amount,
      gross_total_return,
      gross_return_roi_per_year,
      benefits_total_amount,
      roi_expected,
      completed_at,
    } = project;

    const result = {
      ongoing: {
        participations_total_amount: {
          key: 'participations_total_amount',
          value: participations_total_amount,
          label: {
            EUR: 'Mijn deelname',
            USA: 'Mijn deelname',
          },
          type: 'currency',
        },
        benefits_total_amount: {
          key: 'benefits_total_amount',
          value: benefits_total_amount,
          label: {
            EUR: 'Totaal uitkeringen',
            USA: 'Totaal uitkeringen',
          },
          type: 'currency',
        },
        roi_expected: {
          key: 'roi_expected',
          value: roi_expected,
          label: {
            EUR: 'Prognose rendement (ROI per jaar)',
            USA: 'Prognose bruto rendement (ROI per jaar)',
          },
          type: 'percentage',
        },
        completed_at: {
          key: 'completed_at',
          value: completed_at,
          label: {
            EUR: 'Prognose einddatum IM',
            USA: 'Prognose einddatum IM',
          },
          type: 'date',
        },
      },
      completed: {
        participations_total_amount: {
          key: 'participations_total_amount',
          value: participations_total_amount,
          label: {
            EUR: 'Mijn deelname',
            USA: 'Mijn deelname',
          },

          type: 'currency',
        },
        gross_return_roi_per_year: {
          key: 'gross_return_roi_per_year',
          value: gross_return_roi_per_year,
          label: {
            EUR: 'Totaalrendement (ROI per jaar)',
            USA: 'Bruto totaalrendement (ROI per jaar)',
          },

          type: 'percentage',
        },
        gross_total_return: {
          key: 'gross_total_return',
          value: gross_total_return,
          label: {
            EUR: 'Totaalrendement',
            USA: 'Bruto totaalrendement',
          },

          type: 'percentage',
        },
        completed_at: {
          key: 'completed_at',
          value: completed_at,
          label: {
            EUR: 'Einddatum',
            USA: 'Einddatum',
          },
          type: 'date',
        },
      },
    };

    return result;
  };

  parseKPIs = (project) => {
    const { project_kpis } = project;

    const kpis = project_kpis?.[project.status];

    if (!AcIsSet(kpis)) return null;

    const result = Object.keys(kpis).map((key, value) => {
      return kpis[key];
    });

    return result;
  };

  parseStatusTimeline = (project) => {
    const { project_fases } = project;

    if (!AcIsSet(project_fases) || !project_fases?.length) return null;

    return [...project_fases];
  };

  parseKeyData = (project) => {
    const { keydata } = project;

    if (!AcIsSet(keydata) || !keydata?.length) return null;

    return {
      locations: this.parseLocations(project),
      ...keydata,
    };
  };

  parseDocuments = (project) => {
    const { project_files } = project;

    if (!AcIsSet(project_files) || !project_files?.length) return null;

    return project_files;
  };

  parseLocations = (project) => {
    const {
      extra_location1,
      extra_location1_maps,
      extra_location1_maps_show,
      extra_location2,
      extra_location2_maps,
      extra_location2_maps_show,
      extra_location3,
      extra_location3_maps,
      extra_location3_maps_show,
      extra_location4,
      extra_location4_maps,
      extra_location4_maps_show,
      extra_location5,
      extra_location5_maps,
      extra_location5_maps_show,
      url_google_maps,
      show_google_maps,
      location,
      city,
      state,
      country,
    } = project;

    let result = [];

    if (url_google_maps && show_google_maps) {
      let loc = [];
      if (AcIsSet(city) && !AcIsEmptyString(city)) loc.push(city);
      if (AcIsSet(state) && !AcIsEmptyString(state)) loc.push(state);
      if (AcIsSet(country?.name) && !AcIsEmptyString(country?.name))
        loc.push(country?.name);
      result.push({
        name: AcIsSet(location) ? location : loc.join(', '),
        link: url_google_maps,
      });
    }

    if (extra_location1 && extra_location1_maps_show) {
      result.push({
        name: extra_location1,
        link: extra_location1_maps,
      });
    }

    if (extra_location2 && extra_location2_maps_show) {
      result.push({
        name: extra_location2,
        link: extra_location2_maps,
      });
    }

    if (extra_location3 && extra_location3_maps_show) {
      result.push({
        name: extra_location3,
        link: extra_location3_maps,
      });
    }

    if (extra_location4 && extra_location4_maps_show) {
      result.push({
        name: extra_location4,
        link: extra_location4_maps,
      });
    }

    if (extra_location5 && extra_location5_maps_show) {
      result.push({
        name: extra_location5,
        link: extra_location5_maps,
      });
    }

    return result?.length > 0 ? result : null;
  };

  parseUpdates = (project) => {
    const { project_updates } = project;

    if (!AcIsSet(project_updates) || !project_updates?.length) return null;

    return project_updates
      .filter((n) => n.status !== 'draft')
      .map((n) => new ProjectUpdate(n));
  };

  parseMedia = (project) => {
    const { project_images, project_videos, project_updates } = project;

    if (!AcIsSet(project_updates)) return null;

    // 1. We don't need any 'draft' Project Updates here
    // 2. We are adding a property 'type' to images and videos
    // 3. We are filtering the videos, since somehow also non-videos can be added to this collection in the admin...

    const filtered = {
      updates: project_updates?.filter((n) => n.status !== 'draft'),
      images: project_images?.map((n) => ({ ...n, type: 'image' })),
      videos: project_videos
        ?.filter((n) => {
          const type = AcGetTypeOfVideo(n.url);

          return type === 'youtube' || type === 'vimeo';
        })
        .map((n) => ({ ...n, type: 'video', provider: AcGetTypeOfVideo(n.url) })),
    };

    let collection = [...filtered?.images, ...filtered?.videos];

    // Grab all media (images & videos) from the filtered Project Updates
    filtered.updates.forEach((obj, index) => {
      const {
        attachments: { images, videos },
      } = obj;

      // Skip if there are no images and no videos to parse
      if (images?.length === 0 && videos?.length === 0) {
        return;
      }

      // 1. We are adding a property 'type' to images and videos
      // 2. We are filtering the videos, since somehow also non-videos can be added to this collection in the admin...
      const result = {
        images: images?.map((n) => ({ ...n, type: 'image' })),
        videos: videos
          ?.filter((n) => {
            const type = AcGetTypeOfVideo(n.url);

            return type === 'youtube' || type === 'vimeo';
          })
          .map((n) => ({ ...n, type: 'video', provider: AcGetTypeOfVideo(n.url) })),
      };

      collection = [...collection, ...result?.images, ...result?.videos];
    });

    // Sort all media by `created_at` (uploaded), ascending
    collection = collection.sort((a, b) => {
      return new Date(a.created_at) - new Date(b.created_at);
    });

    // All done
    return collection;
  };

  parseFeaturedImages = (project) => {
    const { project_featured_images } = project;

    if (!AcIsSet(project_featured_images)) return null;

    // Sort all media by `created_at` (uploaded), ascending
    const collection = project_featured_images.sort((a, b) => {
      return new Date(a.created_at) - new Date(b.created_at);
    });

    // All done
    return collection;
  };
}
